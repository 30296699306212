<template>
    <div style="position:relative; min-height:100vh">
        <iframe src="https://survey.alchemer.com/s3/6922484/TOU" frameborder="0" allowfullscreen
            style="position:relative;top:0;left:0;width:100%;height:1150px;"></iframe>
    </div>
</template>
<script>
export default {
    name: "terms_of_use",
    mounted() {
        document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    },
};
</script>